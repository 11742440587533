import React from 'react';
import DopFunctional from './DopFunctional';
import ContainerContent from '../Container';
import { useParams } from 'react-router-dom';
import { useFetchMainPostByIdQuery } from '../../redux/services/mainpost';
import Loader from '../Loader';
export default function Posts() {
  const { id } = useParams();
  const { data: mainPostsById, isLoading } = useFetchMainPostByIdQuery(id);
  console.log(mainPostsById);
  return (
    <ContainerContent
      name={mainPostsById?.name}
      content={
        isLoading ? (
          <Loader />
        ) : (
          <DopFunctional
            id={mainPostsById?._id}
            description={mainPostsById?.description}
            images={mainPostsById?.images}
          />
        )
      }
    />
  );
}
